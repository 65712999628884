import React, {Component, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

export const Show = () => {
    let {id} = useParams();
    const [text, setText] = useState('');
    const redirect = () => {
        fetch('url/' + id).then(response => response.json()).then(data => {
            if (data.address)
                window.location.replace(data.address);
            else
                setText("404 Not Found!");

        }).catch(
            error => {
                setText("Error")
            });
    }
    const element = <>
        <div className="card-columns">
            <div className="card p-3">
                <blockquote className="blockquote mb-0 card-body">
                    <p>The last tools that you will buy.</p>
                    <footer className="blockquote-footer">
                        <small className="text-muted">
                            Ronix
                        </small>
                    </footer>
                </blockquote>
            </div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Dizi</h5>
                    <p className="card-text">Best Dizi in Tehran!</p>
                    <p className="card-text"><small className="text-muted">only 6$ for limited time.</small></p>
                </div>
            </div>
            <div className="card bg-primary text-white text-center p-3">
                <blockquote className="blockquote mb-0">
                    <p>Gaz Romoozi, Kabab Ya Dizi, Ood B Seda</p>
                    <footer className="blockquote-footer text-white">
                        <small>
                            The <cite title="Source Title">Datis</cite> Ltd
                        </small>
                    </footer>
                </blockquote>
            </div>
            <div className="card text-center">
                <div className="card-body">
                    <h5 className="card-title">Bijan</h5>
                    <p className="card-text">Care about your blode's Bijan!</p>
                    <p className="card-text"><small className="text-muted">Sauce</small></p>
                </div>
            </div>
            <div className="card p-3 text-right">
                <blockquote className="blockquote mb-0">
                    <p>Koodoomo Bedam?</p>
                    <footer className="blockquote-footer">
                        <small className="text-muted">
                            The <cite title="Source Title">Alis</cite>
                        </small>
                    </footer>
                </blockquote>
            </div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Baya</h5>
                    <p className="card-text">I am Siamak Ansari!</p>
                    <p className="card-text"><small className="text-muted">mmmm</small></p>
                </div>
            </div>

        </div>
        <hr/>
        <div className={'mb-3'}>{text ? text :
            <button className={'btn btn-primary btn-block'} onClick={redirect}>Redirect</button>}</div>
    </>;
    return (
        id && id !== 'create' ? element : ""
    );
}