import React, { Component , useState} from 'react';

export const Create = () => {
    const [url, setUrl] = useState(<></>);
    const [text, setText] = useState('');
    const create = () => {
        fetch('url', {
            method: 'POST', // or 'PUT' , 'DELETE' , 'GET'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({address: text})
        }).then(response => response.json())
            .then(data => {
                let hexId = data.id.toString(16);
                hexId = "0".repeat(12-hexId.length)+hexId;
                console.log(data)
                setUrl(data.id ?
                    <a href={window.location.origin + "/" + hexId}>{window.location.origin + "/" + hexId}</a> :
                    <span className={'text-danger'}>Failed (Invalid Input / Server fault)</span>);
            })
            .catch((error) => {
                setUrl('Failed (Invalid Input / Server fault)');
            });
    }
    return (
        <div>
            <label>Url:</label>
            <input value={text} onChange={(e)=>setText(e.target.value)} className={'form-control'}/>
            <button onClick={create} className={'btn btn-primary mt-1'}>Save</button>
            <hr/>
            {url}
        </div>
    );
}